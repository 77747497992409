import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../utils/windowDimension";
import styles from "../BlogMain.module.css";
import Post from "../Post/Post";
import SideCard from "../SideCard/SideCard";
import HomeSideCard from "./HomeSideCard/HomeSideCard";
import { useEffect } from "react";

const RecentPosts = ({ allData,Heading }) => {
  const { height, width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;
   const [homeMobile, setHomeMobile] = useState(false);
   const locaiton = useLocation();
   const pathname = locaiton.pathname;

   useEffect(() => {
     if (width < 768) {
       setMobile(true);
     } else {
       setMobile(false);
     }
   }, [width]);

   useEffect(() => {
     if (width < 768 && pathname === "/") {
       setHomeMobile(true);
     } else {
       setHomeMobile(false);
     }
   }, [pathname, width]);
  const GoToDetail = (id) => () => {
    navigate(`/blog/${id}`);
  };
  return (
    <div className={`${styles.recentPostContainer}`}>
      <div
        className={` ${styles.articleSectionHeading} ${
          homeMobile && styles.homeH
        }`}
      >
        <span>{Heading}</span>
      </div>
      <div className={styles.recentPost}>
        {allData?.blogs[0] ? (
          <>
            <div className={`${styles.topPost} ${homeMobile && styles.homeP}`}>
              <Post post={allData?.blogs[0]}></Post>
            </div>
            {
              homeMobile && (
                <div className={styles.homeHr}></div>
              )
            }
            
          </>
        ) : (
          <div className={styles.noData}>No Data Found</div>
        )}

        <div className={styles.sideCardContainer}>
          {allData?.blogs[0] ? (
            allData?.blogs?.slice(1, 5)?.map((item, i) => {
              return (
                <div key={item.id}>
                  {route === "/" && mobile ? (
                    <>
                      <HomeSideCard
                        data={item}
                        onClick={GoToDetail(item.id)}
                      ></HomeSideCard>
                      <div className={styles.homeHr}></div>
                    </>
                  ) : (
                    <SideCard data={item}></SideCard>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.noData}>No Data Found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentPosts;
