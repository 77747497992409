import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Base_url } from "../../utils/utils";
import styles from "./SubscriberSec.module.css";
import useWindowDimensions from "../../utils/windowDimension";

const SubscriberSec = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
   const { height, width } = useWindowDimensions();
   const [mobile, setMobile] = useState(false);
   const [successMessage, setSuccessMessage] = useState(null)

   React.useEffect(() => {
     if (width < 768) {
       setMobile(true);
     } else {
       setMobile(false);
     }
   }, [width]);
  const onSubmit = (data) => {
    const newData = {
      email: data.mail2,
    };
    fetch(`${Base_url}/newsletter/subscribe`, {
      method: "POST",
      body: JSON.stringify(newData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let message = "";
        if (data.status_code === 200) {
          message = "Thank you for subscribing!";
        } else {
          message = "This email is already subscribed!";
        }
        setSuccessMessage(message);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      })
      .catch((error) => {
        console.error("API request error: " + error);
      });
  };
  return (
    <div className={`mainContainer`} id="subscribe">
      <div className={`${styles.container}`}>
        {mobile ? (
          <h2>Cut through the noise with our weekly newsletter</h2>
        ) : (
          <h2>
            Join our Newsletter to get the latest news on ESG, Climate Change and more!
          </h2>
        )}
        <p>We promise we won't spam you and you can unsubscribe anytime</p>
        <form
          className={`${styles.emailArea} formValidation`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <input
              {...register("mail2", {
                required: "Email Address is required",
                pattern: {
                  value: /^[a-z0-9]+@[a-z0-9]+\.[a-z]{2,4}$/,
                  message: "Please enter a valid email",
                },
              })}
              aria-invalid={errors.mail2 ? "true" : "false"}
              placeholder="Enter your email"
              className={styles.input}
              style={{ borderColor: errors.mail2 && "red" }}
            />
            {errors.mail2 ? (
              <p role="alert" className="error">
                {errors.mail2.message}
              </p>
            ) : (
              <p role="alert" className="successMsg">
                {successMessage}
              </p>
            )}
          </div>
          <button type="submit" className={styles.button}>
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};
export default SubscriberSec;
