import { Link, NavLink } from "react-router-dom";
import styles from "./MobileMenu.module.css";

import caretDown from "../../assets/icons/caretDown.svg";
const MobileMenu = ({ navLinks, mobileDropdown, setMobileDropdown, setMobileMenuOpen }) => {

  const menuToogle = (item) => {
    if (item.text === "News") {
      setMobileDropdown(!mobileDropdown);
    } else {
      setMobileDropdown(false);
      setMobileMenuOpen(false)
    }
  };

  return (
    <div className={styles.menucontainer}>
      <div className={styles.menucontainer1}>
        {navLinks.map((item, i) => {
          return (
            <li
              key={i}
              className={`${styles.menuItem} ${
                mobileDropdown && item.text === "News" ? styles.active : ""
              }`}
              onClick={() => menuToogle(item)}
            >
              <NavLink
                to={item.link}
                style={({ isActive }) => {
                  return { color: isActive ? "#81298F" : "" };
                }}
              >
                {item?.text}
                {item?.dropdown && (
                  <img
                    src={caretDown}
                    alt="search icon"
                    className={styles.dropdownicon}
                    style={{
                      transform: mobileDropdown
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                  />
                )}
              </NavLink>

              {mobileDropdown && (
                <ul className={styles.dropdownMenu}>
                  {item?.dropdown?.map((item, i) => {
                    return (
                      <li key={i} className={styles.dropdownItem} onClick={() => setMobileMenuOpen(false)}>
                        <Link to={item.link} className={styles.menuItem}>
                          {item.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default MobileMenu;
