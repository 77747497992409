import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import arrowRight from "../../assets/images/arrow-right.svg";
import leftArrow from "../../assets/images/chevron-left.svg";
import greenTick from "../../assets/images/green-tick.svg";
import purpleDot from "../../assets/icons/purpleDot.svg";
import redCross from "../../assets/images/x.svg";
import { Base_url } from "../../utils/utils";
import NewsLetter from "../Newsletter/NewsLetter";
import styles from "./homelatest.module.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetBlogsQuery } from "../../redux/api/bogApi";
import {
  useGetCarouselHomeQuery,
  useGetCompaniesHomeQuery,
} from "../../redux/api/homeApi";
import useWindowDimensions from "../../utils/windowDimension";
import Spinner from "../Spinner/Spinner";
import RecentPosts from "../Blog/RecentPosts/RecentPosts";
import { useGetCompanyColumnQuery } from "../../redux/api/companyApi";

const HomeLatest = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { height,width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);

  const {
    data: companyColumnData,
    isLoading: companyColumnLoading,
    error: companyColumnError,
  } = useGetCompanyColumnQuery();

  const {
    data: companyData,
    isLoading: companyLoading,
    error: companyError,
  } = useGetCompaniesHomeQuery();

  const {
    data: carouselData,
    isLoading: carouselLoading,
    error: carouselError,
  } = useGetCarouselHomeQuery();

  const { data: allData, error, isLoading } = useGetBlogsQuery("1");

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  },[width]);

  if (carouselLoading || companyLoading || isLoading || companyColumnLoading) {
    return(
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (error||companyError||carouselError) {
    console.log(error);
    return <div>Something went wrong</div>;
  }


  const GoToDetail=(id) => () => {
    navigate(`/blog/${id}`);
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  const indicatorStyles = {
    background: "#fff",
    width: 8,
    height: 8,
    borderRadius: 10,
    display: "inline-block",
    margin: "0 8px",
    marginBottom: "10px",
  };

  return (
  <div className={`${styles.homeLatest} mainContainer`}>
    {
      mobile?(

        <RecentPosts allData = { allData } GoToDetail = { GoToDetail } Heading={"Latest From ESGBites"}/>
      ): (
          
      <>
        <div className={styles.latestLeft}>
          <h1 className={styles.heading}>Latest From ESG Bites</h1>
          <div className={styles.carouselDiv}>
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              showStatus={false}
              onChange={(e) => {
                setCurrentSlide(e);
              }}
              statusFormatter={(current, total) => {
                return `Current slide: ${current} / Total: ${total}`;
              }}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <div
                    className=""
                    onClick={onClickHandler}
                    title={label}
                    style={{
                      ...arrowStyles,
                      left: 15,
                      transform: "Rotate(180deg)",
                    }}
                  >
                    <img src={leftArrow} alt="right arrow" />
                  </div>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <div
                    className=""
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, right: 15 }}
                  >
                    <img src={leftArrow} alt="left arrow" />
                  </div>
                )
              }
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                if (isSelected) {
                  return (
                    <li
                      style={{ ...indicatorStyles, background: "#00A3FF" }}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  );
                }
                return (
                  <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                );
              }}
            >
              {carouselData?.blogs?.map((e, i) => (
                <div className="" onClick={()=>navigate(`/blog/${e?.id}`)} key={i}>
                  <img
                    src={`${Base_url}${e?.images[0]?.image}`}
                    alt="carousel"
                    className={styles.carousel__image}
                  />
                  <p className={styles.caption}>{e?.title}</p>
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.latestLeftText}>
            <p className="">
              {carouselData?.blogs[currentSlide]?.content?.length > 300
                ? carouselData?.blogs[currentSlide]?.content?.slice(0, 300) +
                  "..."
                : carouselData?.blogs[currentSlide]?.content}
            </p>
          </div>
        </div>
        <div className={styles.latestRight}>
          <h2 className={styles.rightHeading}>Companies ESG Report</h2>
          <div className={styles.esgtablecontainer}>
            <div className={styles.tablehead}>
              <div className={styles.title}>Company</div>
              {
                companyColumnData?.map((e) => (
                  e.active_company_page && <div className={styles.title1} key={e.id}>{e.name}</div>
                ))
                    }
                  </div>
                  {companyData?.results?.slice(0, 5)?.map((e, i) => (
                    <div className={styles.datarow} key={i}>
                      <div className={styles.namecontainer}>
                        <div className={styles.name}>{e?.name}</div>
                        <div className={styles.sub}>{e?.acronym}</div>
                      </div>
                      {companyColumnData.map((column, index) => (
                        column.active_company_page && (
                          <img
                            className={styles.icon}
                            alt=""
                            src={e[column.name] ? greenTick : redCross}
                            key={index}
                          />
                        )
                      ))}
                    </div>
                  ))}
                  <div className={styles.viewAllParent}>
                    <Link to="/companies">
                      <div className={styles.viewAll}>View all</div>
                <img className={styles.arrowRightIcon} alt="" src={arrowRight} />
              </Link>
            </div>
          </div>
          <NewsLetter />
        </div>
      </>
    
      )
    }
    
    </div >
  );
};

export default HomeLatest;
