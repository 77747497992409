import React from "react";
import styles from "./homearticles.module.css";
import time from "../../assets/images/time.svg";
import { useGetArticlesHomeQuery } from "../../redux/api/homeApi";
import { Base_url } from "../../utils/utils";
import { useNavigate } from "react-router";

const HomeArticles = () => {
  const navigate = useNavigate()
  const {
    data: esgData,
    isLoading: esgLoading,
    error: esgError,
  } = useGetArticlesHomeQuery('esg technology');
  const {
    data: policyData,
    isLoading: policyLoading,
    error: policyError,
  } = useGetArticlesHomeQuery('policy');
  const {
    data: socialData,
    isLoading: socialLoading,
    error: socialError,
  } = useGetArticlesHomeQuery('social governance');

  const {
    data: investmentData,
    isLoading: investmentLoading,
    error: investmentError,
  } = useGetArticlesHomeQuery('investments');

  const {
    data: environmentData,
    isLoading: environmentLoading,
    error: environmentError,
  } = useGetArticlesHomeQuery('environment');

  const {
    data: analysisData,
    isLoading: analysisLoading,
    error: analysisError,
  } = useGetArticlesHomeQuery('opinion');


  if (esgError || policyError || socialError) {
    console.log(esgError || policyError || socialError);
    return <div>Something went wrong</div>;
  }

  if (esgLoading || policyLoading || socialLoading) {
    return (
      <div className="spinner">
        
      </div>
    // 1- env, policy, tech
    )
  }
  return (
    <div className={`${styles.homearticles} mainContainer`}>
      <h2 className={styles.articlesHeading}>Popular Articles</h2>
      <div className={styles.main}>
      <ArticleSection sectionTitle="Environment" data={environmentData?.blogs} navigate={navigate} />
      <ArticleSection sectionTitle="Policy" data={policyData?.blogs} navigate={navigate} />
      <ArticleSection sectionTitle="Technology" data={esgData?.blogs} navigate={navigate} />
        <ArticleSection sectionTitle="Diversity & Inclusion" data={socialData?.blogs} navigate={navigate} />
        <ArticleSection sectionTitle="Investors" data={investmentData?.blogs} navigate={navigate} />
        <ArticleSection sectionTitle="Analysis" data={analysisData?.blogs} navigate={navigate} />
      </div>
    </div>
  );
};

const ArticleSection = ({ sectionTitle, data, navigate }) => (
  <div className={styles.articleSection}>
    <div className={styles.articleSectionHeading}>
      <span>{sectionTitle}</span>
    </div>
    <div className={styles.articleContent1}>
      {data?.length > 0 ? (
        <>
          <div className={styles.card} onClick={() => navigate(`/blog/${data[0]?.id}`)}>
            <img src={`${Base_url}${data[0]?.images[0]?.image}`} alt="card" className={styles.cardImage} />
            <div className={styles.cardText}>
              <div className={styles.cardHeading}>{data[0]?.title}</div>
              <div className={styles.cardTime}>
                <img src={time} alt="time icon" className={styles.cardTimeImage} />
                <p>{data[0]?.duration}-min read</p>
              </div>
            </div>
          </div>
          <hr className="hr" />
          {data.slice(1, 5).map((e) => (
            <div className={styles.subArticle} onClick={() => navigate(`/blog/${e?.id}`)} key={e.id}>
              <div className={styles.subarticleHeading}>{e?.title}</div>
              <div className={styles.cardTime}>
                <img src={time} alt="time icon" className={styles.cardTimeImage} />
                {e?.duration}-min read
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="noData">No Data Found</div>
      )}
    </div>
  </div>
)

export default HomeArticles;
