import React,{ useEffect,useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import search from "../../assets/icons/search.svg";
import dot from "../../assets/images/blackDot.svg";
import greenTick from "../../assets/images/green-tick.svg";
import time from "../../assets/images/time.svg";
import redCross from "../../assets/images/x.svg";
import NewsLetter from "../Newsletter/NewsLetter"

import dateFormat,{ masks } from "dateformat";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../assets/icons/filterIcon.svg";
import {
  useGetCompaniesMoreQuery,
  useGetCompaniesQuery,
  useGetCompaniesSearchQuery,
  useGetCompaniesTrendingQuery,
  useGetCompanyCategoryQuery,
  useGetCompanyColumnQuery,
  useGetFilterCompanyQuery
} from "../../redux/api/companyApi";
import { Base_url } from "../../utils/utils";
import ReactSelect from "../ReactSelect/ReactSelect";
import Spinner from "../Spinner/Spinner";
import SubscriberSec from "../SubscriberSec/SubscriberSec";
import TrendingPosts from "../TrendingPosts/TrendingPosts";
import styles from "./companies.module.css";
import SEO from "../SEO/SEO";

const CompaniePage = () => {
  const [companyArr, setCompanyArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inp, setInp] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [mobile,setMobile] = useState(false)
  const navigate = useNavigate()

  const {
    data: companyColumnData,
    isLoading: companyColumnLoading,
    error: companyColumnError,
  } = useGetCompanyColumnQuery();

  const {
    data: companyData,
    isLoading: companyLoading,
    error: companyError,
  } = useGetCompaniesQuery(currentPage);
  
  const {
    data: companyTrendingData,
    isLoading: companyTrendingLoading,
    error: companyTrendingError,
  } = useGetCompaniesTrendingQuery();

  
  const { data: filterData,isLoading : companyFilterLoading } = useGetFilterCompanyQuery(selectItem);

  const { data: searchData,isLoading : companySearchLoading } = useGetCompaniesSearchQuery({inp,currentPage});
  masks.hammerTime = "d mmm, yyyy";
  useEffect(() => {
    if (inp !== "") {
      setCompanyArr(searchData?.results);
    } else if (selectItem !== "") {
      setCompanyArr(filterData?.results);
    } else {
      setCompanyArr(companyData?.results);
    }
  }, [companyData, searchData, filterData]);

  useEffect(()=>{
    window.innerWidth >= 780 ? setMobile(false) : setMobile(true)
  },[window.innerWidth])

  const { data: categoryData,isLoading,error } = useGetCompanyCategoryQuery();
  const totalPages = Math.ceil(inp === "" && selectItem === "" ? companyData?.count / 10 : companyArr?.length / 10);


  //import company api from rtk query
  if (companyError) {
    console.error(companyError);
  }

  return (
      <div className={`${styles.companies} mainContainer`}>
        {companyLoading || companyTrendingLoading || isLoading || companyColumnLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.mainTitle}>Companies ESG Reports</div>
            <div className={styles.top}>
              <div className={styles.report}>
                <div className={styles.filter}>
                  <div className={styles.select}>
                    <label htmlFor="">Industry : </label>
                    <div className={styles.selectIconContainer}>
                      <div className={styles.selectIcon}>
                        <img src={filterIcon} alt="filterIcon" />
                      </div>
                      <ReactSelect
                        options={categoryData}
                        setSelectItem={setSelectItem}
                        radius={mobile ? 50 : null}
                        defaultValue={categoryData[0]}
                      />
                    </div>
                  </div>
                  <div className={styles.searchBar}>
                    <img src={search} alt="Search Icon" />
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Find Companies"
                      className={
                        mobile
                          ? styles.companiesInputMobile
                          : styles.companiesInput
                      }
                      onChange={(e) => setInp(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.mainCompanyList}>
                <div className={styles.companyList}>
                <div className={styles.companyMainHeader}>
                <div className={styles.companyHeaderDesc}>
                <p className={styles.title}></p>
                <p>Frameworks included in the ESG Report</p> </div>
                  <div className={styles.companyHeader}>
                    <div className={styles.title}>Company</div>
                    {
                      companyColumnData?.map((e) => (
                        e.active_company_page && <div className={styles.title1} key={e.id}>{e.name}</div>
                      ))
                    }
                    <div className={styles.title5}>ESG Report</div>
                  </div>
                  </div>
                  {companySearchLoading || companyFilterLoading ? (
                    <Spinner />
                  ) : companyArr?.length === 0 ||
                    searchData?.results?.length === 0 ||
                    filterData?.results?.length === 0 ? (
                    <div className={styles.noResults}>No Results</div>
                  ) : (
                    companyArr?.map((e, i) => (
                      <>
                          <div className={styles.nameCol} key={e?.id}>
                              <div className={styles.nameContainer}>
                                  <div className={styles.companyLogo}>
                                      <img
                                          className={styles.c_logo}
                                          alt=""
                                          src={e?.logo}
                                      />
                                  </div>
                                  <div className={styles.name}>
                                      <div className={styles.cName}>{e?.name}.</div>
                                      <div className={styles.cCat}>{e?.category}</div>
                                      <div className={styles.cCat}>
                                          Published:{" "}
                                          {dateFormat(e?.published, "hammerTime")}
                                      </div>
                                  </div>
                              </div>
                              {companyColumnData.map((column, index) => (
                                  column.active_company_page && (
                                      <img
                                          className={styles.tikIcon}
                                          alt=""
                                          src={e[column.name] ? greenTick : redCross}
                                          key={index}
                                      />
                                  )
                              ))}
                              <div className={styles.link}>
                                  <a href={e?.esg_report} target="__blank">
                                      <div className={styles.view}>View</div>
                                  </a>
                              </div>
                          </div>
                      </>
                  ))
                  
                  )}
                </div>
                </div>
                <div className={styles.eventsPagination}>
                  {companyArr?.length !== 0 && (
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={setCurrentPage}
                      previousLabel={mobile ? "<" : "< Prev"}
                      nextLabel={mobile ? ">" : "Next >"}
                      previousClassName={styles.prevButton}
                      nextClassName={styles.nextButton}
                    />
                  )}
                </div>
              </div>
              {/* <div className={styles.trending}>
                <div className={styles.trendingPostTitle}>Trending Posts</div>
                <TrendingPosts />
                <div className={styles.featured}>
                  <div className={styles.featuredHeading}>
                    Featured this week
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/blog/${companyTrendingData?.blogs[0]?.id}`)
                    }
                  >
                    <img
                      className={styles.featuredImage}
                      src={`${Base_url}${companyTrendingData?.blogs[0]?.images[0]?.image}`}
                      alt="windmill"
                    />
                    <div className={styles.featuredTitle}>
                      {companyTrendingData?.blogs[0]?.title}
                    </div>
                    <div className={styles.featuredSubtitle}>
                      <div className={styles.subName}>
                        {companyTrendingData?.blogs[0]?.publisher_name}
                      </div>
                      <img src={dot} alt="dot" />
                      <span className={styles.featuredTime}>
                        <img src={time} alt="time" />
                        {companyTrendingData?.blogs[0]?.duration}-min Read
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {companyTrendingData?.blogs?.length !== 0 && (
              <div className={styles.more}>
                <div className={styles.moreHeading}>More on ESG reporting</div>
                <div className={styles.moreCards}>
                  {companyTrendingData?.blogs?.slice(6, 9)?.map((e) => (
                    <div
                      className={styles.moreCard}
                      key={e?.id}
                      onClick={() => navigate(`/blog/${e?.id}`)}
                    >
                      <img
                        src={`${Base_url}${e?.images[0]?.image}`}
                        alt="windmill"
                      />
                      <div className={styles.moreText}>
                        <div className={styles.moreTitle}>{e?.title}</div>
                        <div className={styles.moreSubtitle}>
                          <div className={styles.moreName}>
                            {e?.publisher_name}
                          </div>
                          <img src={dot} alt="dot" />
                          <div className={styles.time}>
                            <img src={time} alt="time" />
                            <p>{e?.duration} min Read</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <SubscriberSec />
          </>
        )}
      </div>
    );
};

export default CompaniePage;
