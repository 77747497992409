import React from 'react'
import styles from "../SearchArticleCard/sArticleCard.module.css"
import time from "../../assets/images/time.svg"
import Dot from "../../assets/images/Dot.svg"
import { Base_url } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'
import dateFormat,{ masks } from "dateformat";

const SArticleCard = ({ item }) => {
  masks.hammerTime = "d mmm, yyyy";

  const navigate = useNavigate()
  const goToDetail = () => () => {
    navigate(`/blog/${item.id}`)
  };
  return (
    <div className={styles.sArticleCard}>
      <img
        src={`${Base_url}${item?.images[0]?.image}`}
        alt="article"
        width={200}
        className={styles.image}
        onClick={goToDetail()}
      />
      <div className={styles.text}>
        <div className={styles.title} onClick={goToDetail()}>
          {item?.title}
        </div>
        <div className={styles.dateTime}>
          <div className={styles.date}>{dateFormat(item?.date_posted, "hammerTime")}</div>
          <div className={styles.time}>
            <span className={styles.dot}>
              <img src={Dot} alt="dot" />
            </span>
            <span className={styles.clock}>
              <img src={time} alt="time" />
            </span>
            <p className={styles.timeText}>{item?.duration} min read</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SArticleCard