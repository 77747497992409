import dateFormat, { masks } from "dateformat";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import shareMobile from "../../assets/icons/shareMobile.svg";
import calender from "../../assets/images/calender.svg";
import locationimg from "../../assets/images/location.svg";
import shareIcon from "../../assets/images/share.svg";
import { Base_url, frontEnd_url } from "../../utils/utils";
import styles from "./upcomingcard.module.css";

const UpcomingCard = ({ item, homeMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState(null);
  masks.hammerTime = "d mmm, yyyy";
  const GoToDetail = (id) => () => {
    navigate(`/events/${id}`);
  };
  useEffect(() => {
    const link = frontEnd_url + "/events/" + item.id;
    setUrl(link);
  }, [item.id]);

  const handleCopy = (id) => () => {
    setCopied(true);
    toast("Copied");
  };

  return (
    <div className={styles.upcomingcard} onClick={GoToDetail(item.id)}>
      <div className={styles.thumb}>
        {item?.image !== undefined && (
          <img
            src={`${Base_url}${item?.image}`}
            alt="img"
            height={259}
            className={styles.cardImage}
          />
        )}
        {homeMobile && (
          <div className={`${styles.shareButton} ${styles.mobileShare}`}>
            <CopyToClipboard text={url} onCopy={handleCopy(item.id)}>
              <img src={shareMobile} alt="share" />
            </CopyToClipboard>
          </div>
        )}
      </div>

      <div className={styles.text}>
        <div className={styles.first}>
          <p className={styles.cardHeading}>
            {item?.about_event?.length > 30
              ? item?.about_event?.slice(0, 30) + "..."
              : item?.about_event}
          </p>
          {!homeMobile && (
            <div className={styles.shareButton}>
              <CopyToClipboard text={url} onCopy={handleCopy(item.id)}>
                <img src={shareIcon} alt="share" />
              </CopyToClipboard>
            </div>
          )}
        </div>
        <p className={styles.cardDate}>
          <img src={calender} alt="calender" />{" "}
          {dateFormat(item?.date, "hammerTime")}
        </p>
        <p className={styles.cardLocation}>
          <img src={locationimg} alt="location" />
          {item?.city}
        </p>
      </div>
    </div>
  );
};

export default UpcomingCard;
