import React, { useEffect, useState } from "react";
import styles from "./aboutpage.module.css";
import SubscriberSec from "../SubscriberSec/SubscriberSec";
import { useGetBlogsQuery } from "../../redux/api/bogApi";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import FeaturedPost from "../BlogDetailsMain/FeaturedPost/FeaturedPost";
import TrendingPosts from "../TrendingPosts/TrendingPosts";
import SEO from "../SEO/SEO";
import {Base_url} from "../../utils/utils"

const AboutPage = () => {
   const { data: allData, error, isLoading } = useGetBlogsQuery("1");
  const [FeaturedData, setFeaturedData] = React.useState([]);
  const [aboutData,setAboutData] = useState({})
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();

  const getAboutData = async ()=>{
    setLoading(true)
    try {
      const response = await fetch('https://backend.esgbites.com/accounts/about-us')
      const data = await response.json();
      setAboutData(data)
      setLoading(false)
    } catch (error) {
      console.log("something went wrong")
      setLoading(false)
    }
  }
  
  useEffect(() => {
    getAboutData()
  }, [])
  
 const GoToDetail = (id) => () => {
    navigate(`/blog/${id}`);
  };
  useEffect(() => {
    if (allData) {
      setFeaturedData(allData.blogs[0]);
    }
  }, [allData]);

   if (isLoading || loading) {
     return <Spinner />;
   }
   if (error) {
     console.log(error);
     return <div>Something went wrong</div>;
   }

  return (
    <>
      
      <h1 className={styles.aboutHeading}>
        {aboutData?.header}
      </h1>
      <div className={styles.about}>
        <div className={styles.left}>
          <div className={styles.vision}>
            <div className={styles.visionTitle}>Our Vision</div>
            <div className={styles.visionText}>
              {aboutData?.vision}
            </div>
          </div>
          <div className={styles.story}>
            <div className={styles.storyHeading}>Our Story</div>
            <div className={styles.storyText}>
             {aboutData?.story}
            </div>
            <img src={aboutData?.image} alt="team" className={styles.teamImage} />
            <div className={styles.value}>
              <h2 className={styles.valueHeading}>
                {aboutData?.values_head}
              </h2>
              <div className={styles.valueItems}>
                {aboutData?.values?.map((e, i) => (
                  <div className={styles.valueItem} key={i}>
                    <div className={styles.valueTitle}>
                      <img src={`${Base_url}${e?.icon}`} alt="logo" />
                      <p>{e?.header}</p>
                    </div>
                    <div className={styles.valueDesc}>{e?.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <TrendingPosts title={"Trending Posts"} />
          <div className={styles.featureDiv}>
          <FeaturedPost />
          </div>
        </div>
      </div>
      <SubscriberSec />
    </>
  );
};

export default AboutPage;
