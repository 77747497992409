import { Base_url } from "../../../../utils/utils";
import time from "../../../../assets/images/time.svg";
import styles from "./HomeSideCard.module.css";
import useWindowDimensions from "../../../../utils/windowDimension";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const HomeSideCard = ({ data }) => {
  const { images, tags, publisher_name } = data;

  const navigate = useNavigate();


 const GoToDetail = (id) => () => {
   navigate(`/blog/${id}`);
 };
  return (
    <div className={styles.articleSection}>
      {images && (
        <>
          <div className={styles.cardImageContainer}>
            {images[0]?.image && (
              <img
                src={` ${Base_url}${images[0]?.image}`}
                alt="thumbnail"
                className={styles.cardImage}
                onClick={GoToDetail(data.id)}
              />
            )}
          </div>
          {<div className={styles.homeHr}></div>}
        </>
      )}
      <div className={styles.cardText}>
        <div className={styles.cardHeading} onClick={GoToDetail(data.id)}>
          {data.title}
        </div>
      </div>
    </div>
  );
};

export default HomeSideCard;
