import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import Layout from './Layout/Layout';
import './index.css';
import store from './redux/store';
import ScrollToTop from './scrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider context={helmetContext}>
          <Layout>
            <ScrollToTop />
            <App />
          </Layout>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);


