import React from 'react'
import { useNavigate } from "react-router-dom"
import styles from "./glosaryCard.module.css"

const GlossaryCard = ({item}) => {
  const navigate = useNavigate()
  return (
    <div className={styles.glossaryCard} onClick={() => navigate('/glossary', {state: { term: item?.term}})}>
        <div className={styles.title}>
        {item?.term}
        </div>
        <div className={styles.text}>
        {item?.description}
        </div>
    </div>
  )
}

export default GlossaryCard