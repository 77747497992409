import React, { useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import caretDown from "../../assets/icons/caretDown.svg";
import menuBar from "../../assets/icons/menuBar.svg";
import search from "../../assets/icons/search.svg";
import x from "../../assets/icons/x.svg";
import purple_b from "../../assets/purple_b.svg";
import purple_name from "../../assets/purple_name.svg";
import useWindowDimensions from "../../utils/windowDimension";
import styles from "./Header.module.css";
import MobileMenu from "./MobileMenu";
import { useGetAboutUsQuery } from "../../redux/api/homeApi";
const Header = () => {
  const { width, height } = useWindowDimensions();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [caret, setCaret] = useState(false);
  const [isMobile, setIsMobile] = useState(width < 768 ? true : false);
  const inputRef = useRef();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const {
    data: aboutUsData,
    isLoading: aboutUsLoading,
    error: aboutUsError,
  } = useGetAboutUsQuery();


  const handleKeyDown = (event) => {
    if (inputRef.current.value !== "") {
      if (event.key === "Enter") {
        navigate(
          `/blogs/universal-search?search=${inputRef.current.value}&page=1`
        );
        inputRef.current.value = ""
        if (isMobileMenuOpen) {
          setMobileMenuOpen(!isMobileMenuOpen)
        }
      }
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const navLinks = [
    {
      text: "Events",
      link: "/events",
    },
    {
      text: "News",
      link: "/blog",
      dropdown: [
        {
          text: "Environment",
          link: "/blog/category?category=environment",
        },
        {
          text: "Policy",
          link: "/blog/category?category=policy",
        },
        {
          text: "Diversity & Inclusion",
          link: "/blog/category?category=social%20governance",
        },
        {
          text: "Technology",
          link: "/blog/category?category=esg%20technology",
        },
        {
          text: "Investors",
          link: "/blog/category?category=investments",
        },
        {
          text: "White Papers",
          link: "/blog/category?category=white%20papers",
        },
        {
          text: "Analysis",
          link: "/blog/category?category=opinion",
        },
      ],
    },
    {
      text: "Companies",
      link: "/companies",
    },
    {
      text: "About Us",
      link: "/about",
    },
  ];

  return(
    <>
      <div className={styles.mainContainer}>
        <nav className={isMobileMenuOpen && styles.brBottom}>
          <div className={`${styles.navContainer}`}>
            <div className={styles.navLeft}>
              <div className={styles.navLogo}>
                <Link to="/">
                  <img src={purple_b} alt="logo" className={styles.logo_b} />
                </Link>
                <Link to="/">
                  <img
                    src={purple_name}
                    alt="logo"
                    className={styles.logo_name}
                  />
                </Link>
              </div>
              <div
                className={styles.mobileMenu}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              >
                <div className={styles.mobileMenuIcon}>
                  {isMobileMenuOpen ? (
                    <img src={x} alt="" />
                  ) : (
                    <img src={menuBar} alt="" />
                  )}
                </div>
              </div>
              <div className={styles.navLinks}>
                {!aboutUsLoading && navLinks.map((item, i) => {
                  return item.link==='/about' && !aboutUsData?.visibility ? null : (
                    <li
                      key={i}
                      className={`${styles.dropdown} ${
                        isDropdownOpen ? styles.open : ""
                      }`}
                      onClick={toggleDropdown}
                    >
                      <NavLink
                        to={item.link}
                        style={({ isActive }) => {
                          return { color: isActive ? "#81298F" : "" };
                        }}
                        onMouseOver={() =>
                          item.text === "News" && setCaret(true)
                        }
                        onMouseLeave={() =>
                          item.text === "News" && setCaret(false)
                        }
                      >
                        <div className={styles.caretDropDown}>
                          {item?.text}
                          {item?.dropdown && (
                            <img
                              src={caretDown}
                              alt="search icon"
                              style={{
                                transform: caret
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)",
                              }}
                              className={styles.caretImage}
                            />
                          )}
                        </div>
                      </NavLink>

                      {item.dropdown && (
                        <ul className={styles.dropdownContent}>
                          {item?.dropdown?.map((item, i) => {
                            return (
                              <li
                                key={i}
                                onMouseOver={() => setCaret(true)}
                                onMouseLeave={() => setCaret(false)}
                              >
                                <Link to={item.link}>{item.text}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </div>
            </div>
            <div className={styles.searchBar}>
              <div className={styles.navSearch}>
                <input
                  type="text"
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                  placeholder={(path === "/" || path.slice(0, 6) === "/blogs") ? "Search for ESG news and more" : "Search"}
                />
                <img src={search} alt="search icon" />
              </div>
            </div>
            <div className={styles.navSubscribe}>
              <a href="#subscribe">
                <button>Subscribe</button>
              </a>
            </div>
            {isMobileMenuOpen && (
              <div className={styles.mobileNavBar}>
                  <div className={styles.mobileNavSearch}>
                    <img src={search} alt="search icon" />
                    <input
                      type="text"
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                      placeholder={path === "/" ? "Search for ESG news and more" : "Search"}
                    />
                  </div>
                {isMobileMenuOpen && (
                  <div className={styles.mobilenav}>
                    <MobileMenu
                      navLinks={navLinks}
                      mobileDropdown={mobileDropdown}
                      setMobileDropdown={setMobileDropdown}
                      caret={caret}
                      setCaret={setCaret}
                      setMobileMenuOpen={setMobileMenuOpen}
                    ></MobileMenu>
                  </div>
                )}
              </div>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
