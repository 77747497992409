import React from 'react'
import styles from './Alphabetical.module.css'

const Alphabetical = ({select,setSelect}) => {    

    const alphabets1 = ["a", "b", "c", "d", "e", "f", "g", "h", "i","j", "k", "l", "m"];
    const alphabets2 = ["n", "o", "p", "q", "r","s", "t", "u", "v", "w", "x", "y", "z"];
    
  return (
    <div className={styles.mainAlpha}>

    <div className={styles.alphabetical}>
    <div className={styles.firstRow}>
    {alphabets1?.map((e) => (
          <span
            className={`${styles.alphabet} ${
              select === e ? styles.alphabetSelect : ""
            }`}
            onClick={() => setSelect(e)}
          >
            {e}
          </span>
        ))}
        </div>
        <div className={styles.secondRow}>
        {alphabets2?.map((e) => (
          <span
            className={`${styles.alphabet} ${
              select === e ? styles.alphabetSelect : ""
            }`}
            onClick={() => setSelect(e)}
          >
            {e}
          </span>
        ))}
        </div>
    </div>
    </div>
  )
}

export default Alphabetical