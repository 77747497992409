import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer, Header } from "../components/index";
import { useLocation,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import metaData from "../helpers/metaData.json";
import SEO from "../components/SEO/SEO";
const Layout = ({ children }) => {
 
  
  return (
    <>
      <SEO />
      <Header />
      <ToastContainer autoClose={1000} position="bottom-right" />
      <main>{children}</main>
      <Footer />
    </>
  );
};
export default Layout;
