import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import styles from "./Glossary.module.css"
import ReactSelect from '../ReactSelect/ReactSelect'
import search from "../../assets/icons/search.svg";
import Alphabetical from '../Alphabetical/Alphabetical';
import TrendingPosts from "../TrendingPosts/TrendingPosts"
import { useGetGlossaryQuery,useGetSearchGlossaryQuery } from '../../redux/api/glossaryApi';
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import SubscriberSec from "../SubscriberSec/SubscriberSec"
import sortIcon from "../../assets/icons/sortIcon.svg"
import AlphabeticalMobile from '../Alphabetical/AlphabeticalMobile';
import SEO from '../SEO/SEO';

const Glossary = () => {

    const [inp, setInp] = useState("");
    const [selectItem, setSelectItem] = useState({label : "Alphabetical",value : "Alphabetical"});
    const [select,setSelect] = useState('a')
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation()
    const [count,setCount] = useState(0)
    
    const {
      data: glossaryData,
      isLoading: glossaryLoading,
      error: glossaryError,
    } = useGetGlossaryQuery(select);

    const {
      data: searchData,
      isLoading: searchLoading,
      error: searchError,
    } = useGetSearchGlossaryQuery({inp,currentPage});

    const [glossary,setGlossary] = useState(glossaryData?.results)
    
    const totalPages = Math.ceil(count/ 6);

    useEffect(() => {
      const redirectTerm = location.state?.term
      if (redirectTerm?.length) {
        setInp(redirectTerm)
      }
    }, [])

    useEffect(()=>{
      if(inp !== ""){
        setCount(searchData?.count)
        setGlossary(searchData?.results)
      }
      else{
        setCount(glossaryData?.count)
        setGlossary(glossaryData?.results)
      }
    },[select,glossaryData,inp,searchData])

  const setValue = (e) => {
    setInp(e.target.value);
  }

  return (
    <div className="mainContainer">
      <SEO
        title="Glossary"
        description="Glossary"
        name="Glossary"
        type="Glossary"
      />
      <div className={styles.glossaryTitle}>Sustainability Dictionary</div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.filter}>
            <div className={styles.sortby}>
              <p>Sort by : </p>
              <div className={styles.sortIcon}>
                <img src={sortIcon} alt="sortIcon" />
              </div>
              <ReactSelect
                options={[
                  { id: 0, label: "Alphabetical", value: "Alphabetical" },
                  { id: 1, label: "Most Viewed", value: "Most Viewed" },
                ]}
                setSelectItem={setSelectItem}
                defaultValue={selectItem}
                page={"glossary"}
                width={window.innerWidth>680 ? "13vw" : ""}
                radius={50}
              />
            </div>
            <div className={styles.searchBar}>
              <img src={search} alt="Search Icon" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Find ESG terms & abbreviations"
                onChange={(e) => setValue(e)}
              />
            </div>
          </div>
          {selectItem.label === "Alphabetical" ? (
            window.innerWidth > 680 ? (
              <Alphabetical select={select} setSelect={setSelect} />
            ) : (
              <AlphabeticalMobile select={select} setSelect={setSelect} />
            )
          ) : (
            ""
          )}
          {glossaryLoading || searchLoading
            ? "Loading..."
            : glossary?.length === 0
            ? "No data"
            : glossary?.map((e) => (
                <div className={styles.glossaryItem}>
                  <div className={styles.itemTitle}>{e?.term}</div>
                  <div className={styles.itemText}>{e?.description}</div>
                  <div className={styles.itemRelated}>
                    Related :{" "}
                    <a href={e?.related_links[0]?.href}>
                      {e?.related_links[0]?.text}
                    </a>
                  </div>
                </div>
              ))}
          <div className={styles.eventsPagination}>
            {glossary?.length !== 0 && (
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
                previousLabel={window.innerWidth > 680 ?  "< Prev" : "<" }
                nextLabel={window.innerWidth > 680 ?  "Next >" : ">" }
                previousClassName={styles.prevButton}
                nextClassName={styles.nextButton}
              />
            )}
          </div>
        </div>
        <div className={styles.right}>
        <div className={styles.trendingPostTitle}>
                Trending Posts
              </div>
          <TrendingPosts />
        </div>
      </div>
      <SubscriberSec />
    </div>
  );
}

export default Glossary