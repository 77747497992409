import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Base_url } from "../../utils/utils";


export const glossaryApi = createApi({
    reducerPath: "glossaryApi",
    baseQuery: fetchBaseQuery({ baseUrl: Base_url }),
    endpoints: (builder) => ({
        getGlossary: builder.query({
            query: (alphabet,page) => `/glossary/glossary/?page_size=6&alphabet_filter=${alphabet}&page=${page ? page : "1"}`,
        }),
        getSearchGlossary: builder.query({
            query: ({ inp,currentPage }) => `/glossary/glossary/?page=${currentPage ? currentPage : "1"}&page_size=6&search=${inp}`,
        }),
    }),
});


export const {
    useGetGlossaryQuery,
    useGetSearchGlossaryQuery
} = glossaryApi;