import React, { useEffect } from "react";
import time from "../../../assets/images/time.svg";
import windmill from "../../../assets/images/windmill.png";
import { useGetBlogsQuery } from "../../../redux/api/bogApi";
import styles from "./FeaturedPost.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { Base_url } from "../../../utils/utils";
const FeaturedPost = () => {
  const { data: allData, error, isLoading } = useGetBlogsQuery("1");
  const [FeaturedData, setFeaturedData] = React.useState([]);
  const [featureLoading, setFeatureLoading] = React.useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const GoToDetail = (id) => () => {
    navigate(`/blog/${id}`);
  };
  useEffect(() => {
    const filteredOut = allData?.blogs?.filter((item) => item.id !== parseInt(params.id));

    if (allData) {
      setFeaturedData(filteredOut[0]);
      setFeatureLoading(false);
    }
  }, [allData, params.id]);

  if (isLoading || featureLoading) {
    return <Spinner />;
  }
  if (error) {
    console.log(error);
    return <div>Something went wrong</div>;
  }
  
  return (
    <div className={styles.featuredPost} onClick={GoToDetail(FeaturedData?.id)}>
      <div className={styles.featuredThisWeek}>Featured this week</div>
      <div className={styles.blogCard}>
        <img alt="featured post" src={`${Base_url}${FeaturedData?.images[0]?.image}`} />
        <div className={styles.tags}>
          {FeaturedData?.tags?.map((tag, i) => (
            <div className={styles.category} key={i}>
              <div className={styles.policy}>{tag.tag}</div>
            </div>
          ))}
        </div>
        <div className={styles.container}>
          <div className={styles.title}>{FeaturedData?.title}</div>
          <div className={styles.footer}>
            <div className={styles.author}>{FeaturedData?.publisher_name}</div>
            <div className={styles.footerChild} />
            <div className={styles.footerItem} />
            <div className={styles.timeArea}>
              <img className={styles.timeIcon} alt="" src={time} />
              <div className={styles.author}>
                {FeaturedData?.duration}-min read
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPost;
