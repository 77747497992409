import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import leftIcon from "../../assets/icons/leftIcon.svg";
import rightIcon from "../../assets/icons/rightIcon.svg";
import viewArrow from "../../assets/images/viewArrow.svg";
import { useGetEventsQuery } from "../../redux/api/eventsApi";
import UpcomingCard from "../UpComingCard/UpcomingCard";
import styles from "./homeupcoming.module.css";

import "swiper/css/navigation";
import useWindowDimensions from "../../utils/windowDimension";

const HomeUpcoming = () => {
  const {
    data: eventData,
    isLoading: eventLoading,
    error: eventError,
  } = useGetEventsQuery();

  const { height, width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;
  const [homeMobile, setHomeMobile] = useState(false);
  const locaiton = useLocation();
  const pathname = locaiton.pathname;

  useEffect(() => {
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (width < 768 && pathname === "/") {
      setHomeMobile(true);
    } else {
      setHomeMobile(false);
    }
  }, [pathname, width]);
  if (eventLoading) {
    return <div className="spinner"></div>;
  }
  if (eventError) {
    console.log(eventError);
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  return (
    <div className={`${styles.homeupcoming} mainContainer`}>
      <div className={styles.top}>
        <p className={styles.heading}>Upcoming Events</p>
        <div className="upwrap">
          {homeMobile ? (
            <Link to="/events" className={styles.headingView}>
              View all events
            </Link>
          ) : (
            <Link to="/events" className={styles.headingView}>
              View all <img src={viewArrow} alt="view" />
            </Link>
          )}
          {homeMobile && (
            <div className="wrapIcon">
              <div className="upcommingPrev">
                <img src={rightIcon} alt="right-arrow" />
              </div>
              <div className="upcommingNext">
                <img src={leftIcon} alt="left-arrow" />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <button onClick={() => swiper.slideNext()}>
        Slide to the next slide
      </button> */}
      <div className={styles.cards}>
        <Swiper
          style={{
            "--swiper-pagination-color": "#81298F",
            "--swiper-pagination-bullet-inactive-color": "#EBF0F0",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
          modules={[Pagination, Navigation, Autoplay]}
          navigation={{
            prevEl: ".upcommingPrev",
            nextEl: ".upcommingNext",
          }}
          spaceBetween={50}
          slidesPerView={3}
          loop={true}
          autoplay={true}
          pagination={{
            clickable: true
          }}
          {...(homeMobile && { pagination: false })}
          // onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          className="upCommingSwiper"
        >
          {eventData?.events?.map((item, i) => (
            <SwiperSlide key={item?.id}>
              <UpcomingCard item={item} homeMobile={homeMobile} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeUpcoming;
