import React, { useState } from "react";
import styles from "./Unsubscribe.module.css"; // Import the CSS module
import Select from "react-select";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/utils";


   const reasonOptions = [
     { value: "no_longer_interested", label: "No longer interested" },
     { value: "too_many_emails", label: "Receiving too many emails" },
     { value: "other", label: "Other" },
   ];

function Unsubscribe() {
const [selectedReason, setSelectedReason] = useState(reasonOptions[0])
  const [customReason, setCustomReason] = useState("");

 
  
  const handleReasonChange = (selectedOption) => {
    setSelectedReason(selectedOption);
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      reason:
        selectedReason.value !== "other" ? selectedReason.value : customReason,
    };
  
    fetch(`${Base_url}/newsletter/subscribe/unsubscribe/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.detail) {
          toast(data.detail);
        } else {
          toast("Unsubscribe failed. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("API request error: " + error);
      });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Unsubscribe from Our Service</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <label>Select a reason for unsubscribing:</label>
          <Select
            value={selectedReason}
            options={reasonOptions}
            onChange={handleReasonChange}
          />
        </div>

        {selectedReason.value === "other" && (
          <div className={styles.inputGroup}>
            <label>Please specify your reason:</label>
            <input
              type="text"
              value={customReason}
              onChange={handleCustomReasonChange}
              className={styles.input}
            />
          </div>
        )}

        <button type="submit" className={styles.submitButton}>
          Unsubscribe
        </button>
      </form>
    </div>
  );
}

export default Unsubscribe;
