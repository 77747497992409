import React,{ useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import terms from "../../helpers/terms";
import { SubscriberSec } from "../index";
import styles from "./terms.module.css";
import SEO from "../SEO/SEO";

const Terms = ({ title }) => {
  const [active, setActive] = React.useState("");
  const [scrolledUp, setScrolledUp] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [currentScrollPos, setCurrentScrollPos] = React.useState(0);
  const location = useLocation()
  const handleClick = (id) => () => {
    scrolledUp && setScrolledUp(false);
    setActive(id);
  };

  useEffect(() => {
    if (active) {
      // get scroll position of element
      const element = document.getElementById(active);
      const position = element.getBoundingClientRect();
      const offset = window.pageYOffset;
      const scroll = position.top + offset;
      setScrollPosition(scroll);
    }
  }, [active]);
  // using scrolled position detect when user scrolls up

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos < scrollPosition) {
        setScrolledUp(true);
      } else {
        setScrolledUp(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);

  }, [scrollPosition]);

  useEffect(() => {
    // get realtime scroll position
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setCurrentScrollPos(currentScrollPos);

    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll",handleScroll);
    
  }, []);



  const tableContent = [
    {
      title: "Acceptance of Terms",
      link: "acceptance",
    },
    {
      title: "Use of content",
      link: "usecontent",
    },
    {
      title: "User contributions",
      link: "contribution",
    },
    {
      title: "Events",
      link: "events",
    },
    {
      title: "Third-party Links",
      link: "termthird",
    },
    {
      title: "Disclaimer of Warranties",
      link: "disclaimer",
    },
    {
      title: "Limitation of Liability",
      link: "limitations",
    },
    {
      title: "Changes to Terms",
      link: "changeterms",
    },
    {
      title: "Governing Law",
      link: "governing",
    },
  ];

  return (
    <>
      <SEO
        title="Terms and Conditions"
        description="Terms and Conditions"
        name="Terms and Conditions"
        type="Terms and Conditions"
      />
      <div className={`${styles.policy} mainContainer`}>
        <div className={styles.policyHeading}>{title}</div>
        {/* <div className={styles.date}>Last updated: 12 March, 2023</div> */}
        <br />
        <div className={styles.main}>
          <div className={styles.right}>
            <div className={styles.subText}>
              Welcome to ESGBites ("the Website"). By accessing and using this
              Website, you agree to comply with and be bound by the following
              terms and conditions. Please read these terms carefully before
              using the Website.
            </div>
            <div className={styles.mobileTableMain}>
            <div className={styles.mobileTable}>
              <div className={styles.mobileTableTitle}>
              Table of Contents
              </div>
              <div className={styles.mobileTableItems}>
                {
                  tableContent?.map((e,i)=>(
                    <div className={styles.mobileTableItem} onClick={handleClick(e.link)}>
                    <a href={`#${active}`}>
                      {i + 1}. <span>{e.title}</span>
                    </a>
                    </div>
                  ))
                }
              </div>
            </div>
            </div>
            {terms.map((e, i) => (
              <div
                className={`${styles.item} ${
                  e.id === active? styles.custMt:''} ${ scrolledUp
                    && styles.noMt}`}
                key={i}
                id={e.id}
              >
                <div className={styles.title}>{`${i + 1}. ${e?.title}`}</div>
                <div className={styles.text}>
                  {e?.subpoint?.length !== 0
                    ? e?.subpoint?.map((elem, index) => (
                        <div className={styles.subItem} key={index}>
                          <div className={styles.subItemTitle}>{`${i + 1}.${
                            index + 1
                          } ${elem?.stitle}`}</div>
                          <div className={styles.subItemText}>
                            {elem?.stext}
                          </div>
                        </div>
                      ))
                    : e?.text}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.left}>
            <div className={styles.leftContainer}>
              <div className={styles.tableHeading}>Table of Contents</div>
              <div className={styles.tableItems}>
                {tableContent?.map((e, i) => (
                  <div
                    className={styles.tableItem}
                    key={i}
                    onClick={handleClick(e.link)}
                    style={{backgroundColor : location?.hash === `#${e?.link}` && "#EDF2F7"}}
                  >
                    {
                      e.link === "events" ? <Link to="/events" className={styles.link}>Events</Link>: <a href={`#${active}`}>{e?.title}</a>
                    }
                   
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <SubscriberSec />
      </div>
    </>
  );
};

export default Terms;
