import React from "react";
import styles from "./Alphabetical.module.css";

const AlphabeticalMobile = ({ select, setSelect }) => {
  const alphabets1 = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];
  const alphabets2 = ["j", "k", "l", "m", "n", "o", "p", "q", "r"];
  const alphabets3 = ["s", "t", "u", "v", "w", "x", "y", "z",""];

  return (
    <div className={styles.alphabetical}>
      <div className={styles.firstRow}>
        {alphabets1?.map((e) => (
          <span
            className={`${styles.alphabet} ${
              select === e ? styles.alphabetSelect : ""
            }`}
            onClick={() => setSelect(e)}
          >
            {e}
          </span>
        ))}
      </div>
      <div className={styles.secondRow}>
        {alphabets2?.map((e) => (
          <span
            className={`${styles.alphabet} ${
              select === e ? styles.alphabetSelect : ""
            }`}
            onClick={() => setSelect(e)}
          >
            {e}
          </span>
        ))}
      </div>
      <div className={styles.thirdRow}>
        {alphabets3?.map((e) => (
          <span
            className={`${styles.alphabet} ${
              select === e ? styles.alphabetSelect : ""
            }`}
            onClick={() => setSelect(e)}
          >
            {e}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AlphabeticalMobile;
