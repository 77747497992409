import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Base_url } from "../../utils/utils";
import styles from "./newsLetter.module.css";

const NewsLetter = () => {
  const [successMessage, setSuccessMessage] = useState(null)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    const newData = {
      email: data.mail3,
    };
    fetch(`${Base_url}/newsletter/subscribe`, {
      method: "POST",
      body: JSON.stringify(newData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let message = ""
        if (data.email) {
          message = "Subscribed successfully";
        } else {
          message = "This email is already subscribed";
        }
        setSuccessMessage(message)
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      })
      .catch((error) => {
        console.error("API request error: " + error);
      });
  };
  return (
    <div className={styles.cut}>
      <h2 className={styles.cutHeading}>
      Cut through the noise with ESG Bites newsletter.
      </h2>
      <p className={styles.p1}>
      Stay updated with the latest sustainability news, trends and analysis. Simple and direct information, delivered directly to your inbox.
      </p>
      {/* <p className={styles.p1}>Join our community of 5,000+ members today.</p> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.emailArea} formValidation`}
      >
        <input
          {...register("mail3", {
            required: "Email Address is required",
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Please enter a valid email address",
            },
          })}
          aria-invalid={errors.mail3 ? "true" : "false"}
          placeholder="Enter your email"
          className={styles.rightInput}
          style={{ borderColor: errors.mail3 && "red" }}
        />
        {errors.mail3 ? (
          <p role="alert" className="error">
            {errors.mail3.message}
          </p>
        ) : (
          <p role="alert" className="successMsg">
            {successMessage}
          </p>
        )}
        <div className={styles.cutButton}>
          <button type="submit" className={styles.rightButton}>
            I'm in
          </button>
          <span className={styles.sideButtonText}>
            Its absolutely free. Seriously.
          </span>
        </div>
      </form>
    </div>
  );
};

export default NewsLetter;
