import React from "react";
import { useNavigate } from "react-router-dom";
import tag from "../../assets/icons/tag.svg";
import time from "../../assets/images/time.svg";
import { useGetCompaniesTrendingQuery } from "../../redux/api/companyApi";
import Post from "../Blog/Post/Post";
import Spinner from "../Spinner/Spinner";
import styles from "./TrendingPosts.module.css";

const TrendingPosts = ({ title, mobile }) => {
  const navigate = useNavigate();
  const {
    data: companyTrendingData,
    isLoading: companyTrendingLoading,
    error: companyTrendingError,
  } = useGetCompaniesTrendingQuery();

  if (companyTrendingLoading) {
    return <Spinner />;
  }

  const GoToCategory = (cat) => () => {
    navigate(`/blog/category?category=${cat}`);
  };
  const GoToDetail = (id) => () => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className={styles.posts}>
      <div className={styles.postHeading}>{title}</div>
      {mobile
        ? companyTrendingData?.blogs?.slice(0, 5).map((e) => (
            <div className={styles.cardBorder} key={e.id}>
              <Post post={e}></Post>
            </div>
          ))
        : companyTrendingData?.blogs?.slice(0, 5).map((e) => (
            <div className={styles.post} key={e.id}>
              <div className={styles.postTitle} onClick={GoToDetail(e.id)}>
                {e?.title}
              </div>
              <div className={styles.postInfo}>
                <div className={styles.time}>
                  <img src={time} alt="time" />
                  <p>{e?.duration} min Read</p>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default TrendingPosts;
