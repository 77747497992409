//setup redux store using react-toolkit

import { configureStore } from "@reduxjs/toolkit";
import { blogApi } from "./api/bogApi";
import { companyApi } from "./api/companyApi";
import { contactApi } from "./api/contactApi";
import { eventsApi } from "./api/eventsApi";
import { glossaryApi } from "./api/glossaryApi";
import { homeApi } from "./api/homeApi";
import { universalSearchApi } from "./api/universalSearchApi";
import { footerApi } from "./api/footerApi";


export default configureStore({
    reducer: {
        [companyApi.reducerPath]: companyApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [homeApi.reducerPath]: homeApi.reducer,
        [glossaryApi.reducerPath]: glossaryApi.reducer,
        [contactApi.reducerPath]: contactApi.reducer,
        [universalSearchApi.reducerPath]: universalSearchApi.reducer,
        [footerApi.reducerPath]: footerApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(companyApi.middleware,blogApi.middleware,eventsApi.middleware,homeApi.middleware,glossaryApi.middleware,contactApi.middleware,universalSearchApi.middleware,footerApi.middleware)

});

