import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
// import metaData from "../../helpers/metaData.json";
export default function SEO() {
  const location = useLocation();
  const path = location.pathname;
  const currentPath = location.pathname;
  const [metaData, setMetaData] = React.useState({});
  const metaTags = metaData[currentPath] || {};
  const navigate = useNavigate();
  const { title, description, name } = metaTags;
  const type = "Organization";


  useEffect(() => {
    fetch("https://backend.esgbites.com/blogs/meta-data-add")
      .then((res) => res.json())
      .then((data) => {
        setMetaData( data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  ,[]);

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={
          metaTags.keywords
            ? metaTags.keywords.join(", ")
            : "ESG, Environmental, Social, Governance, Sustainability, Corporate Responsibility, ESG Report, GRI, SASB, CDP, TCFD, Companies, News, Subscribe, View Latest Report, Industry, Trending Posts, Trending Articles"
        }
      />

      <meta
        name="google-site-verification"
        content="8HSfN1SwnBOuNEGoLRdul1lgAbuqQvrxYzU0yZmRo9g"
      />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={path} />
      <meta property="og:site_name" content={name} />
      <meta property="og:image" content={logo} />

      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
