import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import fbBlack from "../../assets/icons/fbBlack.svg";
import linkBlack from "../../assets/icons/linkBlack.svg";
import linkedinBlack from "../../assets/icons/linkedinBlack.svg";
import mailBlack from "../../assets/icons/mailBlack.svg";
import twitterBlack from "../../assets/icons/twitterBlack.svg";
import fb from "../../assets/icons/fbdark.svg";
import link from "../../assets/icons/link.svg";
import linkedin from "../../assets/icons/linkedindark.svg";
import mail from "../../assets/icons/mail.svg";
import twitter from "../../assets/icons/twitterdark.svg";
import dot from "../../assets/images/Dot.svg";
import { useGetEventDetailQuery } from "../../redux/api/eventsApi";
import { Base_url } from "../../utils/utils";
import styles from "./eventDetailPage.module.css";
import Spinner from "../Spinner/Spinner";
import dateFormat,{ masks } from "dateformat";
import SubscriberSec from "../SubscriberSec/SubscriberSec";
const EventDetailPage = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetEventDetailQuery(id);
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const url = `https://esg-bites.netlify.app${location.pathname}`;
  const navigate = useNavigate();
  masks.hammerTime = 'd mmm, yyyy'
  
  if (error) {
    console.log(error);
    return <div>Something went wrong</div>;
  }
  if (isLoading) {
    return <Spinner />;
  }

  const handleCopy = () => () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000)
  }

  const handleRegistrationRedirect = () => {
    if (data?.register_link) {
      window.location.href = data.register_link;
    }
  }

  return (
    <div className={`${styles.eventdetailpage} mainContainer`}>
      <div className={styles.heading}>Upcoming ESG events</div>
      <div className={styles.subHeading}>
        <div className={styles.text}>
          <span className={styles.text1}>Events</span>
          <span> / </span>
          <span>{data.event}</span>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.card}>
          <div className={styles.image}>
            <img src={`${Base_url}${data.image}`} alt="No img Found" />
          </div>
          <div className={styles.cardText}>
            <div className={styles.overview}>{data && data.event}</div>
            <div className={styles.desc}>{data && data.about_event}</div>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.infoTitle}>Energy Next Symposium</div>
          <div className={styles.infoSubtitle}>
            <span className={styles.by}>by</span>
            <span>42 acres</span>
            <img src={dot} alt="Dot" />
            <div className={styles.free}>Free</div>
          </div>
          <div className={styles.register}>
            <button onClick={handleRegistrationRedirect}> Register</button>
          </div>
          <div className={styles.share}>
            <div className={styles.shareText}>Share :</div>
            <div className={styles.frameGroup}>
              <LinkedinShareButton url={url} quote={data.event}>
                <img
                  className={styles.frameIcon}
                  alt="linkedin"
                  src={linkedinBlack}
                />
              </LinkedinShareButton>
              <TwitterShareButton url={url} quote={data.event}>
                <img
                  className={styles.frameIcon}
                  alt="twitter"
                  src={twitterBlack}
                />
              </TwitterShareButton>
              <FacebookShareButton url={url} quote={data.event}>
                <img className={styles.frameIcon} alt="fb" src={fbBlack} />
              </FacebookShareButton>
              <EmailShareButton url={url} quote={data.event}>
                <img className={styles.frameIcon} alt="mail" src={mailBlack} />
              </EmailShareButton>
              <Tooltip id="copy-tooltip" effect="solid" place="bottom" delayShow={300} delayHide={0} style={{ fontSize: 20, padding: "5px 5px" }} />
              <CopyToClipboard text={url} onCopy={handleCopy()}>
                <div data-tooltip-id="copy-tooltip" data-tooltip-content={copied ? "Copied" : "Copy link"}>
                  <img
                    className={styles.tagFill1Icon}
                    alt="link"
                    src={linkBlack}
                  />
                </div>
              </CopyToClipboard>
            </div>
            <FacebookShareButton quote={data.event} />
          </div>
          <div className={styles.date}>
            <div className={styles.dateTitle}>Date</div>
            <p>{dateFormat(data?.date_posted,'hammerTime')}</p>
          </div>
          <div className={styles.location}>
            <div className={styles.locationTitle}>Location</div>
            <p>{data.city}</p>
          </div>
        </div>
      </div>
      <SubscriberSec/>
    </div>
  );
};

export default EventDetailPage;
