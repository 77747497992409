import React, { useState } from "react";
import Select from "react-select";

const ReactSelect = ({options,setSelectItem,defaultValue,page,width,radius}) => {

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: "#4C4B55",
      backgroundColor: state.isFocused ? "#EDF2F7" : "#fff",
      padding: "12px 16px",
      cursor: "pointer",
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      cursor: "pointer",
      width : width ? width : "170px",
      boxShadow: 'none',
      borderRadius : page === "glossary" && window.innerWidth < 680 ? "100px" : "",
      borderRadius : radius ? radius : "4px",
      border : "2px solid #DCDFE0"
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#4C4B55" // Custom colour
    }),
    menu: provided => ({ ...provided, zIndex: 999,width : "170px",}),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'transparent'
      }),
      menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        "::-webkit-scrollbar" : {
          width : "0.4vw"
        },
        "::-webkit-scrollbar-thumb" : {
          backgroundColor: "lightgrey",
          outline: "1px solid slategrey",
          borderRadius: "10px"
        }
     }),
     
  };

  const handleChange = (selectedOption) => {
    setSelectItem(selectedOption?.id);
    if(page === "glossary"){
      setSelectItem(selectedOption);
    }
    if(page === "eventLocation"){
      setSelectItem(selectedOption?.value)
    }
    if(page === "eventCountry"){
      setSelectItem(selectedOption.id)
    }
  };


  return <div className=""><Select defaultValue={defaultValue} onChange={handleChange} options={options} styles={customStyles} components={{
    IndicatorSeparator: () => null
  }}/></div>
};

export default ReactSelect;
