import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Base_url } from "../../utils/utils";


export const universalSearchApi = createApi({
    reducerPath: "universalSearchApi",
    baseQuery: fetchBaseQuery({ baseUrl: Base_url }),
    endpoints: (builder) => ({
        getSearchData: builder.query({
            query: ({ searchData,currentPage,glossaryCurrentPage }) => `/blogs/universal-search?search=${searchData}&page_blog=${currentPage}&page_glossary=${glossaryCurrentPage }`,
        }),


    }),
});


export const {
    useGetSearchDataQuery,
} = universalSearchApi;