import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetSearchDataQuery } from "../../redux/api/universalSearchApi";
import useWindowDimensions from "../../utils/windowDimension";
import Post from "../Blog/Post/Post";
import GlossaryCard from "../GlossaryCard/GlossaryCard";
import NewsLetter from "../Newsletter/NewsLetter";
import SArticleCard from "../SearchArticleCard/SArticleCard";
import Spinner from "../Spinner/Spinner";
import SubscriberSec from "../SubscriberSec/SubscriberSec";
import TrendingPosts from "../TrendingPosts/TrendingPosts";
import styles from "./search.module.css";

const Search = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [glossaryCurrentPage, setGlossaryCurrentPage] = useState(1);
  const [searchData, setSearchData] = React.useState("string");
  const [tab, setTab] = useState("articles");

  const { data, error, isLoading } = useGetSearchDataQuery({
    searchData,
    currentPage,
    glossaryCurrentPage,
  });
  const totalPages = Math.ceil(data?.blog?.no_of_blogs / 6);
  const glossaryTotalPages = Math.ceil(data?.glossary?.no_of_glossary / 4);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("search");
  const { height, width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const route = location.pathname;
  const [homeMobile, setHomeMobile] = useState(false);
  const locaiton = useLocation();
  const pathname = locaiton.pathname;

  useEffect(() => {
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (width < 768 && pathname == "/blogs/universal-search") {
      setHomeMobile(true);
    } else {
      setHomeMobile(false);
    }
  }, [pathname, width]);

  useEffect(() => {
    setSearchData(myParam);
  }, [myParam]);

  if (error) {
    console.log("error", error);
    return <div>Something went wrong</div>;
  }
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={styles.search}>
      <div className={styles.searchHeading}>
        Showing results for {"‘" + myParam + "’"}
      </div>
      <div className={styles.main}>
        <div className={styles.article}>
          <div
            className={`${styles.articleHeading} ${
              mobile ? styles.mobleH : ""
            }`}
          >
            <span
              className={tab === "articles" ? styles.border : styles.notActive}
              onClick={() => setTab("articles")}
            >
              Articles
            </span>
            {mobile && (
              <span
                className={
                  tab === "dictionary" ? styles.border : styles.notActive
                }
                onClick={() => setTab("dictionary")}
              >
                Sustainability Dictionary
              </span>
            )}
          </div>
          {tab === "articles" ? (
            <div className={styles.mainArticles}>
              {data?.blog?.blogs?.map((e) =>
                mobile ? (
                  <Post post={e} key={e.id} />
                ) : (
                  <SArticleCard item={e} key={e.id} />
                )
              )}
              <div className={styles.pagination}>
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  previousLabel={"< Prev"}
                  nextLabel={"Next >"}
                  previousClassName={styles.prevButton}
                  nextClassName={styles.nextButton}
                />
              </div>
            </div>
          ) : (
            <div className={styles.glossary}>
              {!mobile && (
                <div className={styles.glossaryHeading}>
                  <span className={styles.glossaryHeadingText}>Sustainability Dictionary</span>
                </div>
              )}
              {data.blog.no_of_blogs === 0 ? (
                <div className="NoDataFound">No Data Found</div>
              ) : (
                data?.glossary?.glossary?.map((e) => (
                  <GlossaryCard item={e} key={e.id} />
                ))
              )}
              <div className={styles.pagination}>
                <ResponsivePagination
                  current={glossaryCurrentPage}
                  total={glossaryTotalPages}
                  onPageChange={setGlossaryCurrentPage}
                  previousLabel={"< Prev"}
                  nextLabel={"Next >"}
                  previousClassName={styles.prevButton}
                  nextClassName={styles.nextButton}
                />
              </div>
            </div>
          )}
          {
            !mobile && (
              <div className={styles.glossary}>
              {!mobile && (
                <div className={styles.glossaryHeading}>
                  <span className={styles.glossaryHeadingText}>Sustainability Dictionary</span>
                </div>
              )}
              {data?.glossary?.no_of_glossary === 0 ? (
                <div className="NoDataFound">No Data Found</div>
              ) : (
                data?.glossary?.glossary?.map((e) => (
                  <GlossaryCard item={e} key={e.id} />
                ))
              )}
              <div className={styles.pagination}>
                <ResponsivePagination
                  current={glossaryCurrentPage}
                  total={glossaryTotalPages}
                  onPageChange={setGlossaryCurrentPage}
                  previousLabel={"< Prev"}
                  nextLabel={"Next >"}
                  previousClassName={styles.prevButton}
                  nextClassName={styles.nextButton}
                />
              </div>
            </div>
            )
          }
        </div>
        {!mobile && (
          <div className={styles.popular}>
            <div className={styles.popularArticles}>
              <TrendingPosts title="Popular Articles" />
            </div>
            <div className={styles.newsletter}>
              <NewsLetter />
            </div>
          </div>
        )}
      </div>
      <SubscriberSec />
    </div>
  );
};

export default Search;
