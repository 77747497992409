import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Base_url } from "../../utils/utils";


export const footerApi = createApi({
    reducerPath: "footerApi",
    baseQuery: fetchBaseQuery({ baseUrl: Base_url }),
    endpoints: (builder) => ({
        getFooterLinksItems: builder.query({
            query: () => `/social-media/link-items`,
        }),
        getFooterNewsLinks: builder.query({
            query: () => `/social-media/news-link`,
        }),     
    }),
});


export const {
    useGetFooterLinksItemsQuery,
    useGetFooterNewsLinksQuery,
} = footerApi;