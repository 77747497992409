import { useLocation, useNavigate } from "react-router-dom";
import { Base_url } from "../../../utils/utils";
import styles from "./Post.module.css";
import dot from "../../../assets/images/Dot.svg"
import time from "../../../assets/images/time.svg"
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../utils/windowDimension";
import tag from "../../../assets/icons/tag.svg";
const Post = ({ post, featuredId }) => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);
  const [homeMobile, setHomeMobile] = useState(false);
  const locaiton = useLocation();
  const [categorypage, setCategoryPage] = useState(false);
  const pathname = locaiton.pathname;

  useEffect(() => {
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (width < 768 && pathname === "/") {
      setHomeMobile(true);
    } else {
      setHomeMobile(false);
    }
    if (pathname.includes("category")) {
      setCategoryPage(true);
    }
  }, [pathname, width]);

  const GoToCategory = (cat) => () => {
    navigate(`/blog/category?category=${cat}`);
  };
  const GoToDetail = (id) => () => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className={styles.articleSection}>
      <div className={styles.cardImageContainer}>
        <img
          src={` ${Base_url}${post?.images[0]?.image}`}
          alt="no img"
          className={styles.cardImage}
          onClick={GoToDetail(post?.id)}
        />
        {/* {categorypage && mobile && featuredId ? null : (
          <div className={styles.postTags}>
            <div
              className={styles.postTag}
              onClick={GoToCategory(post?.categories)}
            >
              {post?.categories}
            </div>
            <div
              className={styles.postTag}
              onClick={GoToCategory(post?.tags[0]?.tag)}
            >
              {post?.tags[0]?.tag}
            </div>
          </div>
        )} */}
      </div>

      <div className={styles.cardText}>
        <div className={styles.cardHeading} onClick={GoToDetail(post?.id)}>
          {post?.title}
        </div>
        {/* <div className={styles.postTags}>
          {post?.tags.slice(0,2).map((e) => (
            <>
          <img src={tag} alt="tag" />
            <div className={styles.postTag}>
              {e?.tag}
            </div>
            </>
          ))}
        </div> */}
        <div className={styles.authorName}>
          <span>{post?.publisher_name}</span>
          <img src={dot} alt="dot" />
          <div className={styles.cardTextTagsTime}>
            <img src={time} alt="time" />
            <p>2-min</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
