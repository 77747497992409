import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Base_url } from "../../utils/utils";


export const eventsApi = createApi({
    reducerPath: "eventsApi",
    baseQuery: fetchBaseQuery({ baseUrl: Base_url }),
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: () => `/events/event-view?page=1`
        }),
        getFilteredEvents: builder.query({
            query: ({ selectedlocation,feeData }) => `/events/event-view?page=1&location=${selectedlocation}&mode=${feeData}`
        }),
        getEventDetail: builder.query({
            query: (id) => `/events/event-detail/${id}`,
        }),
        getSearchEvents: builder.query({
            query: ({ searchValue,currentPage }) => `/events/event-search?page=${currentPage}&search=${searchValue}`,
        }),
        getLocationEvents: builder.query({
            query: ({ currentPage,selectedlocation }) => `/events/event-view?page=${currentPage}&location=${selectedlocation}`,
        }),
        getCountryEvents: builder.query({
            query: ({selectedCountry, currentPage}) => `/events/event-view-country?page=${currentPage}&location=${selectedCountry}`,
        }),
    }),
});


export const {
    useGetEventsQuery,
    useGetFilteredEventsQuery,
    useGetEventDetailQuery,
    useGetLocationEventsQuery,
    useGetSearchEventsQuery,
    useGetCountryEventsQuery,
} = eventsApi;