import { useNavigate } from "react-router-dom";
import time from "../../../assets/images/time.svg";
import { Base_url } from "../../../utils/utils";
import styles from "./SideCard.module.css";
import tag from "../../../assets/icons/tag.svg";
import dot from "../../../assets/images/Dot.svg"
const SideCard = ({ data,type }) => {
  const { images,tags,publisher_name } = data;
        const navigate = useNavigate();

        const GoToCategory = (cat) => () => {
          navigate(`/blog/category?category=${cat}`);
        };
        const GoToDetail = (id) => () => {
          navigate(`/blog/${id}`);
        };

        return (
    <div className={styles.articleSection}>
      {images && (
        <div className={styles.cardImageContainer}>
          {images[0]?.image && (
            <img
              src={` ${Base_url}${images[0]?.image}`}
              alt="thumbnail"
              className={styles.cardImage}
              onClick={GoToDetail(data.id)}
            />
          )}
        </div>
      )}
      <div className={styles.cardText}>
        <div className={styles.cardHeading} onClick={GoToDetail(data.id)}>
          {data.title}
        </div>
        <div className={styles.postInfo}>
          {}
          <ul>
            {/* <li
              className={styles.postTag}
              
            >
              <img src={tag} alt="tag" />
              <p>{data?.categories}</p>
            </li> */}
            {/* <li className={styles.postTag} onClick={GoToCategory(tags[0].tag)}>
              {tags[0].tag}
            </li> */}

            <div className={styles.timeContainer}>
              <img src={time} alt="time img" className={styles.cardTimeImage} />
              <p>{data.duration}-min read</p>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideCard;
